import PlanCard from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';
import {
  RawPlanCard, RawPlanCardTimeOut,
  RawSubscriptions,
  RawWrapper,
  RawWrapperTimeOut,
} from '@/domain/services/types';
import PlanCardElement from '@/domain/models/PlanCardElement';

/* eslint camelcase: ["error", {allow: [".*"]}] */
type Sortable = {
  fare_value: number,
};

/* eslint camelcase: ["error", {allow: [".*"]}] */
type SortableByOriginalPrice = {
  fare_original_price: number,
};

function sortByPriceLowerToHigher(obj: Array<RawPlanCardTimeOut>) {
  return obj.sort((a: Sortable, b: Sortable) => {
    if (a.fare_value > b.fare_value) {
      return 1;
    }
    return -1;
  });
}

function sortSubscriptionsByPriceLowerToHigher(obj: Array<RawSubscriptions>) {
  return obj.sort((a: SortableByOriginalPrice, b: SortableByOriginalPrice) => {
    if (a.fare_original_price > b.fare_original_price) {
      return -1;
    }
    return 1;
  });
}

function buildElements(elemnts: Array<string>): Array<PlanCardElement> {
  return elemnts.map((element: string) => {
    const splitElements = element.split(',');
    return new PlanCardElement(splitElements[0], splitElements[1]);
  });
}

function filterAmaproPlan(plans: Array<PlanCard>): Array<PlanCard> {
  const amateurIndex = plans.findIndex((plan: PlanCard) => plan.$name === 'AMATEUR');
  plans.splice(amateurIndex, 1);

  return plans;
}

class PlanCardFactory {
  getPlans(data: RawWrapperTimeOut): Array<PlanCard> {
    return sortByPriceLowerToHigher(data.components.options)
      .map((option: RawPlanCardTimeOut) => {
        const subscriptions = sortSubscriptionsByPriceLowerToHigher(option.packs)
          .map((pack: RawSubscriptions) => new Subscription(
            pack.fare_id,
            pack.fare_name,
            pack.plan_type,
            pack.fare_value,
            pack.fare_price_per_month,
            pack.fare_original_price,
            pack.coupon_duration,
            pack.coupon_amount_discount,
            pack.fare_payment_conditions,
            pack.conditions,
            pack.promo,
            pack.stripe_product_id,
            buildElements(pack.fare_includes_landing),
            option.fare_highlighted_features,
            option.fare_not_includes,
            option.fare_includes,
            pack.active_plan,
            pack.coupon_type,
            pack.fare_price_per_month_coupon,
            pack.new_promo,
            pack.period,
            pack.coupon_new_promo,
          ));
        return new PlanCard(option.fare_id, option.fare_name, option.fare_text, subscriptions);
      });
  }

  getAmaproPlan(data: RawWrapperTimeOut): Array<PlanCard> {
    return filterAmaproPlan(sortByPriceLowerToHigher(data.components.options)
      .map((option: RawPlanCardTimeOut) => {
        const subscriptions = sortSubscriptionsByPriceLowerToHigher(option.packs)
          .map((pack: RawSubscriptions) => new Subscription(
            pack.fare_id,
            pack.fare_name,
            pack.plan_type,
            pack.fare_value,
            pack.fare_price_per_month,
            pack.fare_original_price,
            pack.coupon_duration,
            pack.coupon_amount_discount,
            pack.fare_payment_conditions,
            pack.conditions,
            pack.promo,
            pack.stripe_product_id,
            buildElements(pack.fare_includes_landing),
            option.fare_highlighted_features,
            option.fare_not_includes,
            option.fare_includes,
            pack.active_plan,
            pack.coupon_type,
            pack.fare_price_per_month_coupon,
            pack.new_promo,
            pack.period,
            pack.coupon_new_promo,
          ));
        return new PlanCard(option.fare_id, option.fare_name, option.fare_text, subscriptions);
      }));
  }
}

export default new PlanCardFactory();
